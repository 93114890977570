.SearchBar {
  width: 330px;
  height: 50px;
  font-size: 20px;
  padding-left: 20px;
  border-radius: 24px;
  background: linear-gradient(145deg, #56a7da, #66c6ff);
  box-shadow: 9px 9px 18px #519dce, -9px -9px 18px #6dd5ff;
  border: none;
  outline: none;
}
.SearchBar:focus {
  border-radius: 24px;
  box-shadow: inset 9px 9px 18px #519dce, inset -9px -9px 18px #6dd5ff;
  border: none;
}
.Input-container {
  position: relative;
}
.searchBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 30px;
}
