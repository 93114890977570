.addItemBar {
  margin-bottom: 20px;
  width: 330px;
  height: 50px;
  font-size: 20px;
  padding-left: 20px;
  border-radius: 24px;
  background: linear-gradient(145deg, #56a7da, #66c6ff);
  box-shadow: 9px 9px 18px #519dce, -9px -9px 18px #6dd5ff;
  border: none;
  outline: none;
}
.addItemBar:focus {
  border-radius: 24px;
  box-shadow: inset 9px 9px 18px #519dce, inset -9px -9px 18px #6dd5ff;
  border: none;
}
.addBtn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 18px;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  background: transparent;
  border: none;
  height: 50px;
  padding: 10px;
  background: linear-gradient(145deg, #66c6ff, #56a7da);
  box-shadow: 5px 5px 10px #4689b3, -5px -5px 10px #78e9ff;
  transition: all 3s ease-in-out;
}

.addBtn:active {
  background: linear-gradient(145deg, #56a7da, #66c6ff);
  box-shadow: 5px 5px 10px #4689b3, -5px -5px 10px #78e9ff;
}
