@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #5fb9f2;
}
.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 450px;
  min-height: 680px;
  border-radius: 24px;
  background: #5fb9f2;
  box-shadow: 9px 9px 18px #519dce, -9px -9px 18px #6dd5ff;
}
